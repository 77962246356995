import Image from 'next/image';
import Link from 'next/link';
import { GetServerSideProps } from 'next/types';
import { allSettled, fork, serialize } from 'effector';
import { useUnit } from 'effector-react';

import { MainLayout } from '@/layouts/main-layout';

import { categoriesQuery } from '@/entities/catalog/model';
import { promotionsQuery, Stock } from '@/entities/stock';

import { Category } from '@/shared/api/generated/api.generated';
import { Button } from '@/shared/components/ui/button';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/shared/components/ui/carousel';
import { pagesPath } from '@/shared/config/$path';
import { EFFECTOR_STATE_KEY } from '@/shared/lib/effector';
import { PageSeo } from '@/shared/lib/seo';

import { indexPage } from './index.model';

export const getServerSideProps: GetServerSideProps = async (context) => {
  const scope = fork();

  await allSettled(indexPage.open, { scope, params: {} });

  return {
    props: {
      [EFFECTOR_STATE_KEY]: serialize(scope),
    },
  };
};

const HomePage = () => {
  const promotions = useUnit(promotionsQuery);
  const [categoriesArray] = useUnit([categoriesQuery.$data]);
  return (
    <>
      <PageSeo title={'Главная'} />

      <MainLayout>
        <div className="relative flex h-[80vh]">
          <div className="container relative z-20 my-auto text-white">
            <p className="text-3xl">Металлообрабатывающие станки</p>
            <p className="mt-2 text-xl">Станки европейского качества</p>
            <Button variant="secondary" className="mt-4" asChild>
              <Link href={pagesPath.catalog._categoryId('a44bc79f-2901-4346-8428-6e9cd7de9e01').$url()}>Подробнее</Link>
            </Button>
          </div>
          <div className="absolute bottom-0 left-0 right-0 top-0 z-10 h-full w-full bg-black/80" />
          <video
            className="pointer-events-none absolute bottom-0 left-0 right-0 top-0 flex h-full w-full touch-none object-cover"
            playsInline
            autoPlay
            muted
            loop
          >
            <source src="/videos/video-on-index.webm" type="video/webm" />
            <source src="/videos/video-on-index.mov" type="video/mp4" />
          </video>
        </div>
        <div className="container">
          <div className="space-y-2">
            <h1 className="mt-7 text-3xl font-bold">О компании</h1>
            <p className="text-wrap text-xl">
              Дом Станков - ваш надежный партнер в сфере станочного оборудования. Мы специализируемся на поставке
              высококачественных станков для обработки дерева и металла, предлагая широкий ассортимент оборудования от
              ведущих производителей. Наш опыт и профессионализм позволяют нам предложить клиентам оптимальные решения
              для любых задач по обработке материалов. Мы гарантируем надежность, качество и индивидуальный подход к
              каждому клиенту, помогая им повысить эффективность производства и достичь высоких результатов. Доверьтесь
              нам, и ваш бизнес получит надежную поддержку в области станочного оборудования.
            </p>
          </div>
          <h2 className="mt-7 text-3xl font-bold">Продажа промышленного оборудования по всей России</h2>
          <div className="mt-4 grid grid-cols-1 gap-2 lg:grid-cols-5">
            {categoriesArray?.data?.map((category: Category) => {
              return (
                <div key={category.id} id={category.id} className="mt-4 border p-2 hover:shadow-md lg:mt-0">
                  <Link href={pagesPath.catalog._categoryId(category.id).$url()} className="">
                    <div className="relative h-[200px] md:h-[300px]">
                      <Image src={category.image!} fill alt={category.name} className="object-contain" />
                    </div>
                  </Link>
                  <h3 className="mt-2 text-center lg:mt-0">{category.name}</h3>
                </div>
              );
              // <CatalogCard key={category.id} category={category} />;
            })}
          </div>

          {promotions.data && promotions.data?.length > 1 && (
            <div className="mt-4">
              <Carousel className="space-y-4">
                <div className="flex items-center justify-between">
                  <h3 className="text-center text-3xl font-bold">Акции</h3>
                  <div className="flex items-center space-x-3">
                    <CarouselPrevious className="relative left-0 top-0 translate-y-0 " />
                    <CarouselNext className="relative left-0 top-0 translate-y-0" />
                  </div>
                </div>
                <CarouselContent>
                  {promotions.data?.map((item) => {
                    return (
                      <CarouselItem key={item.id} className="h-full basis-3/4 md:basis-1/2 lg:basis-1/3">
                        <Stock img={item.image} discount={item.discount} name={item.name} />
                      </CarouselItem>
                    );
                  })}
                </CarouselContent>
              </Carousel>
            </div>
          )}
        </div>
      </MainLayout>
    </>
  );
};

export default HomePage;
