import { createQuery } from '@farfetched/core';
import { createEffect } from 'effector';

import {
  findAllCategory,
  FindAllCategoryParams,
  findCategoryById,
  findCategoryFiltersByCategoryId,
} from '@/shared/api/generated/api.generated';

export const categoriesQuery = createQuery({
  effect: createEffect((params: FindAllCategoryParams) => {
    return findAllCategory(params);
  }),
});

export const categoryByIdQuery = createQuery({
  effect: createEffect((id: string) => {
    return findCategoryById(id);
  }),
});

export const categoryFiltersQuery = createQuery({
  effect: createEffect((id: string) => {
    return findCategoryFiltersByCategoryId(id);
  }),
});
