import { createQuery } from '@farfetched/core';
import { createEffect } from 'effector';

import { findAllPromotion } from '@/shared/api/generated/api.generated';

export const promotionsQuery = createQuery({
  effect: createEffect(() => {
    return findAllPromotion();
  }),
});
