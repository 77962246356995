import Image from 'next/image';
import Link from 'next/link';

import { Badge } from '@/shared/components/ui/badge';

interface StockProps {
  img: string;
  dateOfPromoCreated?: string;
  dateOfPromoDeleted?: string;
  discount: number;
  name: string;
}

export const Stock = (props: StockProps) => {
  const { img, dateOfPromoCreated, dateOfPromoDeleted, discount, name } = props;
  return (
    <div className="col-span-1 flex flex-col">
      <Link href="/" className="relative flex h-[350px] flex-col hover:text-red-400">
        <div className="relative flex h-full overflow-hidden rounded-xl">
          <Image src={img} fill objectFit="cover" alt="Металлообрабатывающее оборудование" className="z-0" />
          <div className="absolute bottom-4 left-4 z-10">
            <Badge>{`до -${discount}%`}</Badge>
            <Badge variant="secondary" className="ml-2">
              9д:12ч:29м
            </Badge>
          </div>
        </div>
        <h3 className="mt-2 text-2xl ">{name}</h3>
      </Link>
    </div>
  );
};
